import { useCallback, useEffect, useRef, useState } from "react";

export const useTeaserCards = () => {
	const cardsContainerRef = useRef<HTMLDivElement | null>(null);
	const cardListRef = useRef<HTMLDivElement | null>(null);
	const cardRefs = useRef<Array<HTMLDivElement | null>>([]);
	const [currentCardIndex, setCurrentCardIndex] = useState(0);
	const [canScrollPrev, setCanScrollPrev] = useState(false);
	const [canScrollNext, setCanScrollNext] = useState(false);

	useEffect(() => {
		const handleScrollResize = () => {
			requestAnimationFrame(() => {
				const cardsContainerScrollLeft = Math.round(
					cardsContainerRef.current?.scrollLeft ?? 0,
				);
				const cardsContainerWidth = Math.round(
					cardsContainerRef.current?.clientWidth ?? 0,
				);
				const cardListWidth = Math.round(
					cardListRef.current?.clientWidth ?? 0,
				);

				const cardOffsets = cardRefs.current.map((cardRef) =>
					Math.round(cardRef?.offsetLeft ?? 0),
				);
				/**
				 * Calculate the index of the currently visible card.
				 * The scroll position is compared with the offsetLeft positions of all cards.
				 * If the scroll position is greater than the maximum offsetLeft, the last card is visible.
				 * Otherwise, the index of the first card that has an offsetLeft >= scroll position is the visible card.
				 * Example with card offsets [0, 404, 808]:
				 * if `cardsContainerScrollLeft` === 0; then `currentIndex` = 0
				 * if 0 > `cardsContainerScrollLeft` <= 404; then `currentIndex` = 1
				 * if 404 > `cardsContainerScrollLeft` <= 808; then `currentIndex` = 2
				 * if `cardsContainerScrollLeft` > 808; then `currentIndex` = 2
				 */
				const currentIndex =
					cardsContainerScrollLeft > Math.max(...cardOffsets)
						? cardOffsets.length - 1
						: cardOffsets.findIndex(
								(cardOffset) =>
									cardsContainerScrollLeft <= cardOffset,
							);

				setCurrentCardIndex(currentIndex);
				setCanScrollPrev(cardsContainerScrollLeft > 0);
				setCanScrollNext(
					cardsContainerScrollLeft <
						cardListWidth - cardsContainerWidth,
				);
			});
		};

		handleScrollResize();
		const cardsContainer = cardsContainerRef.current;
		cardsContainer?.addEventListener("scroll", handleScrollResize);
		window.addEventListener("resize", handleScrollResize);
		return () => {
			cardsContainer?.removeEventListener("scroll", handleScrollResize);
			window.removeEventListener("resize", handleScrollResize);
		};
	}, []);

	const handleScrollPrev = useCallback(() => {
		const prevCard = cardRefs.current[currentCardIndex - 1];
		if (cardsContainerRef.current && prevCard) {
			cardsContainerRef.current.scrollTo({
				left: prevCard.offsetLeft,
				behavior: "smooth",
			});
		}
	}, [currentCardIndex]);

	const handleScrollNext = useCallback(() => {
		const nextCard = cardRefs.current[currentCardIndex + 1];
		if (cardsContainerRef.current && nextCard) {
			cardsContainerRef.current.scrollTo({
				left: nextCard.offsetLeft,
				behavior: "smooth",
			});
		}
	}, [currentCardIndex]);

	return {
		cardsContainerRef,
		cardListRef,
		cardRefs,
		canScrollPrev,
		canScrollNext,
		handleScrollPrev,
		handleScrollNext,
	};
};
