"use client";

import { cn } from "@peerigon/pupper/tailwind";
import { getFragmentData } from "src/__generated__";
import { ColumnLayoutTypes } from "src/__generated__/graphql";
import { HeaderFragment } from "src/components/Navigation/Navigation";
import SubNavigationItem from "src/components/Navigation/SubNavigationItem";
import { getPathMenuColumns } from "src/components/helpers";
import { usePathname } from "src/i18n/routing";
import type { FragmentType } from "src/__generated__";

type SubNavigationProps = {
	data: FragmentType<typeof HeaderFragment>;
	localizedPathname: Record<string, Record<string, string>>;
};

const SubNavigation: React.FC<SubNavigationProps> = ({
	data,
	localizedPathname,
}) => {
	const dataFromFragment = getFragmentData(HeaderFragment, data);
	const pathname = usePathname();

	const { items } = dataFromFragment;

	const rootPath = getPathMenuColumns(items, pathname);

	if (!rootPath) return null;

	return (
		<div className="my-px-60 flex flex-col items-center justify-center gap-px-40 md:my-xxx-large md:gap-px-80">
			<h1 className="text-center text-[32px] font-bold leading-medium text-black md:text-5xl md:leading-[154%]">
				{rootPath.menuColumn.title}
			</h1>
			<div
				className={cn(
					"grid grid-cols-1 gap-1 px-6 md:grid-cols-2 md:gap-3",
					rootPath.menuColumn.layout === ColumnLayoutTypes.col_3 &&
						"lg:grid-cols-3",
				)}
			>
				{rootPath.menuColumn.items.map((item) => (
					<SubNavigationItem
						key={item.title}
						data={item}
						pathname={pathname}
					/>
				))}
			</div>
		</div>
	);
};

export default SubNavigation;
